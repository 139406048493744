
export default {
  name: 'SearchWrapper',
  props: {
    page: {
      type: String,
      default: () => 'search'
    }
  },
  data() {
    return {
      filterList: [],
      dataModel: [
        'workplacePolicy',
        'degree',
        'salary',
        'experience',
      ],
      form: {
        "degree": [],
        "experience": [],
        "financing": [],
        "industry": [],
        "salary": [],
        "strength": [],
        "workplacePolicy": []
      },
      visible: [false, false, false, false],
      isPostPage: false
    }
  },
  fetch() {
    if (this.$route.path.indexOf('/apply') > -1) {
      this.isPostPage = true
    } else {
      this.isPostPage = false
    }
  },
  methods: {
    async getData() {
      const res = await this.$api.jobseeker.caFilter()
      this.filterList  = res.data.data.slice(0, 4)
    },
    onChange() {
      const filter = {}
      for(let key in this.form) {
        const v = this.form[key]
        // filter[key] = v.map(i => Number(i))
        const new_v = (typeof v === 'string' && v !== '') ? [Number(v)] : []
        filter[key] = new_v
      }
      this.$root.context.store.commit('searchFilter/setFilter', filter);
      this.$root.$emit('search')
    },
    onVisibleChange(visible, index) {
      this.$set(this.visible, index, visible)
    }
  },
  async mounted() {
    await this.getData()
  }
};
